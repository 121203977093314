<template>
  <div class="product">
    <div class="phone-head-public animate__animated animate__fadeIn">
      <img src="@/assets/img/banner4.png" alt="" />
    </div>
    <div class="cnt">
      <div class="left">
        <p
          :class="pitch == index ? 'in' : ''"
          v-for="(item, index) in typeList"
          :key="index"
          @click="cut(item, index)"
        >
          {{ item.name }}
        </p>
      </div>
      <div class="right">
        <div v-for="(item, index) in list" :key="index" @click="skip(item)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {
      typeList: [],
      list: [],
      pitch: 0,
    };
  },
  methods: {
    skip(item) {
      this.$router.push({ path: "/Pproduct/info", query: { id: item.id,name:item.name } });
    },
    cut(item, index) {
      this.pitch = index;
      this.axios({
        method: "get",
        url: "/wzn/product-categories/page",
        params: {
          pageNo: 1,
          pageSize: 100,
          parentId: item.id,
        },
      }).then((res) => {
        console.log("产品分类", res.data);
        this.list = res.data.data.list.reverse();
      });
    },
  },
  created() {
    this.axios({
      method: "get",
      url: "/wzn/product-categories/page",
      params: {
        pageNo: 1,
        pageSize: 100,
        parentId: 0,
      },
    }).then((res) => {
      console.log("分类", res.data);
      this.typeList = res.data.data.list.reverse();
    });
    this.cut({ id: 1 }, 0);
  },
};
</script>
    
<style scoped>
.cnt {
  height: 80vh;
  display: flex;
}
.left {
  width: 25%;
  background: #f6f6f6;
}
.left p {
  padding: 20px 0;
  text-align: center;
  color: #676767;
  font-size: 14px;
  transition: all 0.45s;
}
.left p.in {
  color: #fff;
  background: red;
}
.right{
  flex: 1;
}
.right>div{
  width: 80%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #f6f6f6;
  margin: 20px auto 0;
  border-radius: 10px;
}
</style>